<template>
  <div id="section3">
    <div class="fondoSilver">
      <b-row align-v="center" v-responsive.md.lg.xl style="margin: 0">
        <b-col md="6" class="text-right" >
          <br>
          <h2>
            {{$t("payTitulo")}}
          </h2>
          <h4 style="padding-left: 5em">
            {{$t("payText")}}
          </h4>
          <br>
          <div>
            <a @click="menuClicked(payUrl)" class="btn buttonTurqueza" >
              {{$t("payBtn")}}
            </a>
          </div>
          <br>
        </b-col>
        <b-col md="6" style="padding: 0">
          <b-img :src="payImg" class="imgContS5"/>
        </b-col>
      </b-row> 
      <b-row align-v="center" v-responsive.sm.xs>
        <b-col sm="12" class="text-center" >
          <br>
          <h2>
            {{$t("payTitulo")}}
          </h2>
          <h4>
            {{$t("payText")}}
          </h4>
          <br>
          <div>
            <a @click="menuClicked(payUrl)" class="btn buttonTurqueza" >
              {{$t("payBtn")}}
            </a>
          </div>
          <br>
        </b-col>
      </b-row>     
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "Tips",
    "payText": "A través de este servicio podrás facturar la propina a través del pago con tarjeta.",
    "payBtn": "MORE INFORMATION"
  },
  "es":{
    "payTitulo": "Propinas",
    "payText": "Through this service you can bill the tip through card payment.",
    "payBtn": "MÁS INFORMACIÓN"
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payUrl: "page_pay2.2",
      payImg: require("@/assets/images/pay/servicios_propinas.jpg")
    }
  },
  methods: {
    menuClicked(itemName) {
      itemName += '_'+this.$i18n.locale;
      if (itemName.includes("page_pay")) this.$router.push({ name: itemName });
    }
  }
};
</script>