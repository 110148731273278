<template>
  <div id="section2">
    <div class="fondoWhite">
      <b-row align-v="center" v-responsive.md.lg.xl style="margin: 0">
        <b-col md="6" style="padding: 0">
          <b-img :src="payImg" class="imgContS5"/>
        </b-col>
        <b-col md="6" class="text-left" >
          <br>
          <h2>
            {{$t("payTitulo")}}
          </h2>
          <h4 style="padding-right: 5em">
            {{$t("payText")}}
          </h4>
          <br>
        </b-col>
      </b-row>    
      <b-row align-v="center" v-responsive.sm.xs>
        <b-col sm="12" class="text-center" >
          <br>
          <h2>
            {{$t("payTitulo")}}
          </h2>
          <h4>
            {{$t("payText")}}
          </h4>
          <br>
        </b-col>
      </b-row>  
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "Tax free",
    "payText": "The Tax free service facilitates the return of VAT to customers who are not residents of European Union."
  },
  "es":{
    "payTitulo": "Tax free",
    "payText": "El servicio Tax free facilita la devolución del IVA a los clientes no residentes en la Unión Europea."
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payImg: require("@/assets/images/pay/servicios_tax_free.jpg")
    }
  }
};
</script>