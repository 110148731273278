<template>
  <div id="section1">
    <div class="fondoFwhite" :style="'background-image: url(' + payImg + ');'">  
      <b-container fluid>
        <b-container>
          <div class="HeadContainer">
            <div class="HeadVertical-center">
              <h1>
                <span v-for="(item, idx) in paytitle" :key="idx">
                  {{item}} <br/>
                </span>
              </h1>
            </div>
          </div>
        </b-container>
      </b-container>
    </div>      
  </div>
</template>

<i18n>
{
  "en":{
    "paytitle": [
      "Offering a dynamic conversion of "
      , "Currency, tip acceptance and "
      , "and other services that will advance "
      , "your business."
    ]
  },
  "es":{
    "paytitle": [
      "Ofrece conversión dinámica de "
      , "moneda, la aceptación de propinas "
      , "y otros servicios que harán crecer tu "
      , "negocio."
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payImg: require("@/assets/images/pay/servicios-slider-bg.jpg")
    }
  },
  computed: {
    paytitle() {
      return this.$t("paytitle");
    }
  }
};
</script>
