<template>
  <div id="section2">
    <div class="fondoWhite">
      <b-row align-v="center" v-responsive.md.lg.xl style="margin: 0">
        <b-col md="6" style="padding: 0">
          <b-img :src="payImg" class="imgContS5"/>
        </b-col>
        <b-col md="6" class="text-left" >
          <br>
          <h2>
            {{$t("payTitulo")}}
          </h2>
          <h4 style="padding-right: 5em">
            {{$t("payText")}}
          </h4>
          <br>
          <div>
            <a @click="menuClicked(payUrl)" class="btn buttonTurqueza" >
              {{$t("payBtn")}}
            </a>
          </div>
          <br>
        </b-col>
      </b-row>    
      <b-row align-v="center" v-responsive.sm.xs>
        <b-col sm="12" class="text-center" >
          <br>
          <h2>
            {{$t("payTitulo")}}
          </h2>
          <h4>
            {{$t("payText")}}
          </h4>
          <br>
          <div>
            <a @click="menuClicked(payUrl)" class="btn buttonTurqueza" >
              {{$t("payBtn")}}
            </a>
          </div>
          <br>
        </b-col>
      </b-row>        
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "DCC service",
    "payText": "This service allows foreign customers to pay in their home currency.",
    "payBtn": "MORE INFORMATION"
  },
  "es":{
    "payTitulo": "Servicio DCC",
    "payText": "Es un servicio que permite al cliente extranjero pagar en su divisa de origen.",
    "payBtn": "MÁS INFORMACIÓN"
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payUrl: "page_pay2.1",
      payImg: require("@/assets/images/pay/servicios_dcc.jpg")
    }
  },
  methods: {
    menuClicked(itemName) {
      itemName += '_'+this.$i18n.locale;
      if (itemName.includes("page_pay")) this.$router.push({ name: itemName });
    }
  }
};
</script>